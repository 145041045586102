export const SLOT_DEAL_MESSAGE_FRAGMENT = `
  fragment slotDealMessageFragment on Deal {
    uid
    slots: _slots(filter: { code: "message_feature" }) {
      uid
      code
      quantity
      strategy
      type
    }
  }
`;
